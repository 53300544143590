import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import { ProjectCard } from "./ProjectCard";
import projImg10 from "../assets/img/project-img10.png";
import projImg11 from "../assets/img/project-img11.png";
import projImg12 from "../assets/img/project-img12.png";
import projImg13 from "../assets/img/project-img13.png";
import projImg14 from "../assets/img/project-img14.png";
import projImg16 from "../assets/img/product_1.webp";
import projImg17 from "../assets/img/product_2.webp";
import projImg18 from "../assets/img/master_1.webp";
import projImg19 from "../assets/img/master_2.webp";
import projImg20 from "../assets/img/master_3.webp";
import projImg21 from "../assets/img/master_4.webp";
import projImg22 from "../assets/img/master_5.webp";
import projImg23 from "../assets/img/master_6.webp";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
// import projImg1 from "../assets/img/project-img1.png";
// import projImg2 from "../assets/img/project-img2.png";
// import projImg3 from "../assets/img/project-img3.png";

export const Projects = () => {
  const projects = [
    {
      title: "Комплектующие",
      description:
        "Комплектующие и запчасти для оборудования полимерной промышленности",
      imgUrl: projImg10,
    },
    {
      title: "Экструзия",
      description: "Экструзионные линии",
      imgUrl: projImg11,
    },
    {
      title: "Дробилки для пластика",
      description:
        "Оборудование для измельчения пластиковых отходов, которое превращает их в мелкие фракции.",
      imgUrl: projImg12,
    },
    {
      title: "Пресс-формы",
      description: "Изготовление пресс-форм для литья пластмасс",
      imgUrl: projImg13,
    },
    {
      title: "Охлаждение",
      description: "Охлаждение и контроль температуры оборудования",
      imgUrl: projImg14,
    },
  ];
  const products = [
    {
      title: "Полиэтилен ПВД",
      description: [
        "Классическая экструзия",
        "Плоскощелевая экструзия",
        "Выдувная экструзия",
        "Литьевое производство",
      ],
      imgUrl: projImg16,
    },
    {
      title: "Полиэтилен ПНД",
      description: [
        "Классическая экструзия",
        "Плоскощелевая экструзия",
        "Выдувная экструзия",
        "Литьевое производство",
      ],
      imgUrl: projImg17,
    },
  ];
  const master = [
    {
      title: "Суперконцентраты для полиэтилена и полипропилена",
      description: [
        "Белые суперконцентраты",
        "Суперконцентраты добавок",
        "Цветные суперконцентраты",
        "Черные суперконцентраты",
      ],
      imgUrl: projImg18,
    },
    {
      title: "Монопигментные суперконцентраты",
      description: [
        "Монопигментные суперконцентраты для полиамида (нити, пленки, компаунды)",
        "Монопигментные суперконцентраты для полиэтилена, полипропилена (пленки, нити, компаунды)",
        "Монопигментные суперконцентраты для полиэтилентерефталата (ПЭТ)",
      ],
      imgUrl: projImg19,
    },
    {
      title: "Суперконцентраты для производства кабелей",
      description: [
        "Суперконцентраты для безгалогенных кабелей",
        "Суперконцентраты для кабельной оболочки ПВХ",
        "Суперконцентраты для оптоволоконных кабелей",
        "Суперконцентраты для ПВХ автопроводов Т2-Т3",
        "Суперконцентраты для ПЭ и ПП кабелей",
      ],
      imgUrl: projImg20,
    },
    {
      title: "Суперконцентраты для полиэтилентелефталата",
      description: [
        "Белые суперконцентраты для ПЭТ",
        "Добавки для ПЭТ",
        "Цветные суперконцентраты для ПЭТ",
        "Черные суперконцентраты для ПЭТ",
      ],
      imgUrl: projImg21,
    },
    {
      title: "Добавки для полиамидов",
      description: [
        "Добавки для Анионной полимеризации",
        "Добавки для компаундирования",
      ],
      imgUrl: projImg22,
    },
    {
      title: "Суперконцентраты для труб, фитингов и оконных профилей",
      description: ["Суперконцентраты для труб, фитингов и оконных профилей"],
      imgUrl: projImg23,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Наша продукция</h2>
                  <p>
                    Мы предлагаем разнообразный и тщательно подобранный
                    ассортимент продукции, который охватывает все аспекты
                    переработки пластмасс и производства. В нашей линейке
                    представлены передовые решения, предназначенные для
                    обеспечения эффективности и высокой производительности на
                    каждом этапе технологического процесса.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      // className="nav-pills mb-5 justify-content-center align-items-center"
                      className="custom-nav nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          Оборудование{" "}
                          <spaan className="subtext">для пластмасс</spaan>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Сырьё для производства
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Мастербатчи</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {products.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {master.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="Задний фон"
      ></img>
    </section>
  );
};
