import { Col, Row } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col md={12} lg={12} xl={6}>
            <h3>Контактная информаци</h3>
            <span className="newsletter-bx-box">
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">Адрес: </span>
                <span className="newsletter-bx-text">
                  Санкт-Петербург, Краснопутиловская ул., д. 69 литер А,
                  помещение 315н, офис 217а
                </span>
              </span>
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">Телефон:</span>{" "}
                <a href="tel:+79217679299" className="newsletter-bx-actions">
                  +7 921 767 92 99
                </a>
              </span>
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">Электронная почта:</span>{" "}
                <a
                  href="mailto:polymerfinans@gmail.com"
                  className="newsletter-bx-actions"
                >
                  polymerfinans@gmail.com
                </a>
              </span>
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">График работы:</span>{" "}
                <span className="newsletter-bx-text">
                  Пн-Пт 9:00-19:00 Сб-Вc — выходной{" "}
                </span>
              </span>
            </span>
            <h3>Реквизиты</h3>
            <span className="newsletter-bx-box">
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">ООО «Полимерфинанс»</span>
              </span>
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">Адрес:</span>
                <span className="newsletter-bx-text">
                  Санкт-Петербург, Краснопутиловская ул., д. 69 литер А,
                  помещение 315н, офис 217а
                </span>
              </span>
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">ОГРН:</span>
                <span className="newsletter-bx-text">1227800136772</span>
              </span>
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">ИНН:</span>
                <span className="newsletter-bx-text"> 7814814851</span>
              </span>
              <span className="newsletter-bx-section">
                <span className="newsletter-bx-title">КПП:</span>
                <span className="newsletter-bx-text">780501001</span>
              </span>
            </span>
          </Col>
          <Col md={12} lg={12} xl={6}>
            <span className="newsletter-map">
              <iframe
                title="Расположение офиса Polymer"
                src="https://yandex.ru/map-widget/v1/-/CDxdBC7I"
                width="100%"
                height="100%"
                frameBorder="0"
                // allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </span>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
