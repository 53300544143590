import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          {typeof description === "string" ? (
            <span>{description}</span> // Если описание — строка, выводим его
          ) : (
            description.map((item, index) => (
              <span key={index}>
                {item}
                <br /> {/* Добавим разрыв строки между элементами массива */}
              </span>
            ))
          )}
          {/* <span>{description}</span> */}
        </div>
      </div>
    </Col>
  );
};
