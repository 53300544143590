import React from "react";
import Accordion from "./Accordion";

const FaqSecondary = () => {
  return (
    <div>
      <Accordion />
    </div>
  );
};

export default FaqSecondary;
