import { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/img/header_img_polymer.png";
import navIcon1 from "../assets/img/wtsp.svg";
// import logo from "../assets/img/logo_main_new.webp";
// import logo from "../assets/img/logo_main.webp";
// import logo from '../assets/img/logo.svg';
// import navIcon2 from "../assets/img/nav-icon2.svg";
// import navIcon3 from "../assets/img/nav-icon3.svg";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="Logo"
              style={{
                height: 24,
                width: 24,
                objectFit: "contain",
                marginRight: 10,
              }}
            />
            <span className="logo-title">POLYMER</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Главная
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={`${
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                  ${"tablet"}`}
                onClick={() => onUpdateActiveLink("skills")}
              >
                Решения для бизнеса
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={`${
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                  ${"desktop"}`}
                onClick={() => onUpdateActiveLink("skills")}
              >
                Решения
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("projects")}
              >
                Наша продукция
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://wa.me/79217679299">
                  <img src={navIcon1} alt="Связаться по Whatsapp" />
                </a>
              </div>
              <HashLink to="#connect">
                <button className="vvd">
                  <span>Связаться</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
