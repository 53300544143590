import React, { useState } from "react";
import { motion } from "framer-motion";
import style from "./Accordion.module.css";
import { faq } from "../constants/second";

const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div
      className={`${
        !isOpen
          ? style.accordion_item
          : `${style.accordion_item} ${style.accordion_item_active}`
      }
            `}
    >
      <div className={style.accordion_header} onClick={toggleAccordion}>
        <span
          className={`${style.accordion_img}
          ${!isOpen ? style.arrow : `${style.arrow} ${style.active}`}
            `}
        />
        <span
          className={`
            ${!isOpen ? style.title : `${style.title} ${style.title_active}`}
            `}
        >
          {title}
        </span>
      </div>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isOpen ? "auto" : 0 }}
        transition={{ duration: 0.3 }}
        style={{ overflow: "hidden" }}
      >
        <div className={style.accordion_body}>{children}</div>
      </motion.div>
    </div>
  );
};

const Accordion = () => {
  return (
    <div class="container">
      <div className={style.accordion}>
        <h2>То, о чём вам не стоит беспокоиться</h2>
        {faq.map((item) => (
          <AccordionItem key={item.id} title={item.title}>
            <span className={style.text}>{item.description}</span>
          </AccordionItem>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
