import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/header_img_polymer.png";
import navIcon1 from "../assets/img/wtsp_b.svg";
// import logo from "../assets/img/logo.svg";
// import navIcon2 from "../assets/img/nav-icon2.svg";
// import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <span className="footer-logo">
              <img
                src={logo}
                alt="Logo"
                style={{
                  height: 24,
                  width: 24,
                  objectFit: "contain",
                  marginRight: 10,
                }}
              />
              <span className="footer-logo-title">POLYMER</span>
            </span>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon-b">
              <a href="https://wa.me/79217679299">
                <img src={navIcon1} alt="Связаться по Whatsapp" />
              </a>
            </div>
            <p>© 2024 ООО «Полимерфинанс»</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
