import secondary1 from "../assets/img/secondary_1.webp";
import secondary2 from "../assets/img/secondary_2.webp";
import secondary3 from "../assets/img/secondary_3.webp";
import secondary4 from "../assets/img/secondary_4.webp";
import secondary5 from "../assets/img/secondary_5.webp";
import secondary6 from "../assets/img/secondary_6.webp";
import secondary7 from "../assets/img/secondary_7.webp";
import secondary8 from "../assets/img/secondary_8.webp";
import secondary9 from "../assets/img/secondary_9.webp";
import secondary10 from "../assets/img/secondary_10.webp";
import secondary11 from "../assets/img/secondary_11.webp";
import secondary12 from "../assets/img/secondary_12.webp";

export const faq = [
  {
    id: "1",
    title: "Можно ли многократно использовать вторичные полимерные гранулы?",
    description:
      "Да, вторичные полимерные гранулы прекрасно подходят для повторного использования, что делает их идеальным решением для экологичного производства.",
  },
  {
    id: "2",
    title:
      "Какое влияние оказывает использование вторичных полимерных гранул на окружающую среду?",
    description:
      "Использование вторичных полимерных гранул помогает значительно сократить углеродный след, уменьшить отходы, снизить загрязнение воды и воздуха, а также экономить ресурсы и энергию при производстве.",
  },
  {
    id: "3",
    title:
      "Могут ли вторичные полимерные гранулы использоваться для упаковки пищевых продуктов?",
    description:
      "Да, при условии соблюдения всех стандартов безопасности, вторичные полимерные гранулы подходят для упаковки пищевых продуктов, если они произведены из безопасного сырья.",
  },
  {
    id: "4",
    title: "Какие виды вторичных полимерных гранул доступны для производства?",
    description:
      "Мы предлагаем широкий ассортимент вторичных полимерных гранул, включая АБС, ПВД, ПНД, ПОМ, ПП, ПС и стрейч, обеспечивая разнообразие в зависимости от нужд вашего производства.",
  },
  {
    id: "5",
    title:
      "Как вторичные полимерные гранулы используются в различных отраслях?",
    description:
      "Вторичные гранулы активно применяются для производства полиэтиленовых пленок, одноразовой посуды, напольных покрытий, утеплителей и других востребованных товаров, обеспечивая высокое качество и доступность.",
  },
  {
    id: "6",
    title: "Чем отличаются первичные и вторичные полимерные гранулы?",
    description:
      "Основное отличие между первичными и вторичными гранулами заключается в сырье: первичные гранулы получаются из нового материала, а вторичные — из переработанных пластиковых отходов. При этом вторичные гранулы более экономичны.",
  },
  {
    id: "7",
    title: "Что такое вторичные полимерные гранулы и как они производятся?",
    description:
      "Вторичные полимерные гранулы — это продукт переработки пластиковых отходов, получаемый методом переплавки, который позволяет использовать их для новых производственных процессов и снизить нагрузку на природу.",
  },
  {
    id: "8",
    title: "Используются ли вторичные полимерные гранулы в строительстве?",
    description:
      "Да, в строительстве вторичные полимерные гранулы применяются для производства различных строительных материалов, включая пленки, крышные покрытия и дорожные покрытия.",
  },
  {
    id: "9",
    title: "Насколько безопасно работать с вторичными полимерными гранулами?",
    description:
      "При соблюдении всех стандартов переработки и хранения, работа с вторичными полимерными гранулами абсолютно безопасна и не представляет угрозы для здоровья.",
  },
  {
    id: "10",
    title:
      "В чем основные преимущества вторичных полимерных гранул перед первичным материалом?",
    description:
      "Вторичные гранулы обеспечивают существенную экономию, так как они дешевле и помогают снизить энергозатраты, а также загрязнение окружающей среды и объем отходов.",
  },
  {
    id: "11",
    title: "Какой минимальный объём заказа полимерных гранул можно оформить?",
    description:
      "У нас нет минимальных ограничений по объему заказа — вы можете заказать хоть 1 кг! Также мы предоставляем бесплатные пробные образцы для ваших экспериментов.",
  },
  {
    id: "12",
    title: "Какие способы доставки доступны для полимерных гранул?",
    description:
      "Мы обеспечиваем отгрузку под самовывоз, но также можем организовать доставку через надежных партнеров, таких как «Деловые Линии», чтобы предложить вам выгодные условия транспортировки.",
  },
  {
    id: "13",
    title: "Почему у вас нет в наличии ПВХ гранул?",
    description:
      "Процесс переработки ПВХ сложен из-за выделения токсичных веществ, что требует дополнительных мер безопасности. Мы не перерабатываем ПВХ, но можем предложить альтернативы в виде ПВД или ПНД.",
  },
];

export const secondaryMaterials = [
  {
    title: "Вторичка ПВД",
    description: [
      "Классическая экструзия",
      "Плоскощелевая экструзия",
      "Выдувная экструзия",
      "Литьевое производство",
    ],
    imgUrl: secondary1,
  },
  {
    title: "Дроблёный ПНД",
    description: [
      "Классическая экструзия",
      "Плоскощелевая экструзия",
      "Выдувная экструзия",
      "Литьевое производство",
    ],
    imgUrl: secondary2,
  },
  {
    title: "Вторичка ПП",
    description: [
      "Классическая экструзия",
      "Плоскощелевая экструзия",
      "Выдувная экструзия",
      "Литьевое производство",
    ],
    imgUrl: secondary3,
  },
  {
    title: "Дробленый ПС",
    description: ["Классическая экструзия", "Литьевое производство"],
    imgUrl: secondary4,
  },
  {
    title: "Вторичка АБС",
    description: ["Литьевое производство"],
    imgUrl: secondary5,
  },
  {
    title: "Дробленый ПВД",
    description: ["Классическая экструзия", "Литьевое производство"],
    imgUrl: secondary6,
  },
  {
    title: "Дробленый ПП",
    description: [
      "Классическая экструзия",
      "Плоскощелевая экструзия",
      "Выдувная экструзия",
      "Литьевое производство",
    ],
    imgUrl: secondary7,
  },
  {
    title: "Полимерный краситель",
    description: ["Окрашивание", "Пигментация"],
    imgUrl: secondary8,
  },
  {
    title: "Порошок ПНД",
    description: ["ДПК", "Ротоформовка"],
    imgUrl: secondary9,
  },
  {
    title: "Вторичка ПС",
    description: ["Классическая экструзия", "Литьевое производство"],
    imgUrl: secondary10,
  },
  {
    title: "Вторичка стрейч",
    description: ["Литьевое производство"],
    imgUrl: secondary11,
  },
  {
    title: "Вторичка ПНД",
    description: [
      "Классическая экструзия",
      "Плоскощелевая экструзия",
      "Выдувная экструзия",
      "Литьевое производство",
    ],
    imgUrl: secondary12,
  },
];
